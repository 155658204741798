import { createContext, useCallback, useContext, useState } from 'react';
import { BarcodeContainer } from './BarcodeContainer';
import { DEFAULT_TEMPLATE } from './constants';
import { ElementForm } from './ElementForm';
import { Elements } from './Elements';
import { PrintingPreview } from './PrintingPreview';
import { Size } from './Size';
import { Toolbar } from './Toolbar';
import { deleteProp, updateObj } from './utils';
import { Variables } from './Variables';

const EditorContext = createContext({ template: DEFAULT_TEMPLATE });
export const useEditor = () => useContext(EditorContext);

export const DocumentEditor = ({ template, setTemplate, printers }) => {
  const [previewRef, setPreviewRef] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [editingPath, setEditingPath] = useState(null);
  const [size, setSize] = useState({ height: null, width: null });
  const [printing, setPrinting] = useState(false);
  const [keyValues, setKeyValues] = useState({});

  const update = useCallback(
    (path, newValue) => {
      setTemplate((prev) => {
        const obj = JSON.parse(JSON.stringify(prev));
        updateObj(obj, path, newValue);
        return obj;
      });
    },
    [setTemplate]
  );

  const getConfig = (path) => {
    if (!path) return null;
    var stack = path.split('.');
    let object = template;

    while (stack.length > 0) {
      let step = stack.shift();
      if (step.match(/\[\d+\]/)) {
        step = Number(step.replace(/[[\]]/g, ''));
      }

      object = object?.[step];
    }

    return object;
  };

  const deletePath = (path) => {
    setTemplate((prev) => {
      const obj = JSON.parse(JSON.stringify(prev));
      deleteProp(obj, path);
      return obj;
    });
  };

  return (
    <EditorContext.Provider
      value={{
        template,
        setTemplate,
        update,
        getConfig,
        editingPath,
        setEditingPath,
        deletePath,
        size,
        setSize,
        previewRef,
        setPreviewRef,
        previewImage,
        setPreviewImage,
        printing,
        setPrinting,
        printers,
        keyValues,
        setKeyValues,
      }}
    >
      <div style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden', width: '100%' }}>
        <Toolbar />
        <div
          style={{ display: 'flex', flexGrow: 1, overflow: 'hidden', width: '100%', borderBottom: '1px solid #ccc' }}
        >
          <Variables />
          <BarcodeContainer />
          <Elements />
        </div>
        <Size />
      </div>
      <ElementForm />
      <PrintingPreview />
    </EditorContext.Provider>
  );
};
