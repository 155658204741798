import { HomeWrapper } from '../components/styled';
import { MODEL } from '../constants/models';
import { useData } from '../store';

export const Home = () => {
  const theme = window.localStorage.getItem('dx-theme');
  const { name, surname = '', username } = useData(MODEL.PROFILE);

  const displayName = name ? `${name} ${surname ?? ''}` : username;

  return (
    <HomeWrapper style={{ color: theme === 'dx.generic.dark' ? '#ffffff' : '#333333' }}>
      <h1>Te damos la bienvenida</h1>
      <h2>{displayName}</h2>
    </HomeWrapper>
  );
};
