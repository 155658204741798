import styled from 'styled-components';

export const AppHeaderWrapper = styled.header`
  background: #a30000;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #ffffff;
  padding: 0 32px;

  & > h2 {
    text-transform: uppercase;
    font-size: 14px;
  }
`;

export const AppMenuWrapper = styled.div`
  max-width: 200px;
  padding: 0 4px;
  border-right: 1px solid #dee1e3;
`;

export const AppContentWrapper = styled.div`
  display: flex;
  overflow: hidden;
  width: 100%;

  & > * {
    ${({ isMobile }) => {
      if (isMobile) {
        return 'display: grid; place-content: center;';
      }

      return '';
    }}
    height: calc(100vh - 64px);
    overflow: hidden auto;
    width: 100%;
  }
`;

export const LoginFormWrapper = styled.div`
  width: 320px;
  margin: 0 auto;

  & > form {
    margin-top: 64px;
    border: 1px solid #dee1e3;
    border-radius: 8px;
    padding: 16px 24px;
  }
`;

export const HomeWrapper = styled.div`
  display: grid;
  place-content: center;
  text-align: center;
  height: 100%;

  h2 {
    font-weight: normal;
  }
`;
