import { Navigate, Route, Routes } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './pages/Home';
import { Login } from './pages/Login';
import { Templates } from './pages/Templates';
import { useIsMobile } from './utils/hooks';
import { locale, loadMessages } from 'devextreme/localization';
import esMessages from 'devextreme/localization/messages/es.json';
import { useData } from './store';
import { MODEL } from './constants/models';
import Variables from './pages/Variables';

loadMessages(esMessages);
locale(navigator.language);

const App = () => {
  const isAuth = useData(MODEL.PROFILE);
  const isMobile = useIsMobile();

  return (
    <Layout>
      <Routes>
        <Route path="/" element={!isAuth ? <Login /> : <Home />} />
        {isAuth && !isMobile && (
          <>
            <Route path="/templates" element={<Templates />} />
            <Route path="/variables" element={<Variables />} />
          </>
        )}{' '}
        <Route path="*" element={<Navigate to="/" replace={true} />} />
      </Routes>
    </Layout>
  );
};

export default App;
