import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { MODEL } from '../constants/models';
import { useData } from '../store';

export const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const handleWindowSizeChange = () => setIsMobile(window.innerWidth <= 768);

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);

    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);
  return isMobile;
};

export const useCurrentPathTitle = () => {
  const isAuth = useData(MODEL.PROFILE);
  const { pathname } = useLocation();

  const names = {
    '/': isAuth ? 'Inicio' : 'Iniciar sesión',
    '/templates': 'Plantillas',
    '/variables': 'Variables',
  };

  return names[pathname.toLowerCase()] ?? pathname;
};
