export const ToolbarItems = {
  Add: {
    name: 'addRowButton',
    location: 'before',
    options: { text: '', hint: 'Nuevo' },
    showText: false,
  },
  Delete: (onClick) => ({
    name: 'deleteMany',
    widget: 'dxButton',
    location: 'before',
    options: { text: '', hint: 'Eliminar', icon: 'trash', onClick, type: 'danger', stylingMode: 'outlined' },
    visible: true,
    showText: false,
  }),
  Refresh: (onClick) => ({
    name: 'refresh',
    widget: 'dxButton',
    location: 'before',
    options: { text: '', hint: 'Actualizar', icon: 'refresh', onClick },
    visible: true,
    showText: false,
  }),
  Register: (onClick) => (ref) => ({
    name: 'register',
    widget: 'dxButton',
    location: 'after',
    options: { text: 'Alta', onClick: () => onClick?.(ref) },
    visible: true,
    showText: true,
  }),
  Modify: (onClick) => (ref) => ({
    name: 'modify',
    widget: 'dxButton',
    location: 'after',
    options: { text: 'Modificación', onClick: () => onClick?.(ref) },
    visible: true,
    showText: true,
  }),
  Remove: (onClick) => (ref) => ({
    name: 'delete',
    widget: 'dxButton',
    location: 'after',
    options: { text: 'Baja', onClick: () => onClick?.(ref) },
    visible: true,
    showText: true,
  }),
  ColumnChooser: {
    name: 'columnChooserButton',
    location: 'after',
    options: { text: '', hint: 'Columnas' },
    showText: false,
  },
  Export: { name: 'exportButton', location: 'after', options: { text: 'Exportar', hint: 'Exportar' }, showText: false },
};

export const FormFields = {
  Username: {
    name: 'username',
    label: { text: 'Usuario' },
    editorType: 'dxTextBox',
    isRequired: true,
    editorOptions: { name: 'username' },
  },
  Password: {
    name: 'password',
    label: { text: 'Contraseña' },
    editorType: 'dxTextBox',
    editorOptions: { mode: 'password', name: 'password' },
    isRequired: true,
  },
  RememberMe: {
    name: 'rememberMe',
    editorType: 'dxCheckBox',
    editorOptions: {
      text: 'Recuérdame',
      name: 'rememberMe',
    },
    horizontalAlignment: 'center',
  },
  Submit: {
    itemType: 'button',
    buttonOptions: {
      text: 'Iniciar sesión',
      type: 'default',
      icon: 'key',
      useSubmitBehavior: true,
    },
    horizontalAlignment: 'center',
  },
};
