import axios from 'axios';
import { DataGrid, FileUploader, Popup } from 'devextreme-react';
import { useEffect, useRef, useState } from 'react';
import { io } from 'socket.io-client';
import { BASE_URL } from '../../constants/api';
import { MODEL } from '../../constants/models';
import { useAPI, useData, useDataSource } from '../../store';
import { DocumentEditor } from './DocumentEditor';
import { DEFAULT_TEMPLATE } from './DocumentEditor/constants';
import { notification } from './DocumentEditor/utils';
import JSZip from 'jszip';
import FileSaver from 'file-saver';

export const Templates = () => {
  const api = useAPI();
  const ref = useRef();
  const confirmRef = useRef();
  const importPopupRef = useRef();
  const establishment = useData(MODEL.ESTABLISHMENT);
  const dataSource = useDataSource(MODEL.TEMPLATE);
  const variablesDataSource = useDataSource(MODEL.VARIABLE);
  const [template, setTemplate] = useState();
  const [printers, setPrinters] = useState([]);
  const [socket, setSocket] = useState(null);

  const handleAdd = () => setTemplate(DEFAULT_TEMPLATE);

  const handleRefresh = () => {
    axios.get('/template').then(({ data }) => {
      api.set(MODEL.TEMPLATE, data);
    });
  };

  const askDelete = () => {
    const { selectedRowKeys } = ref.current.instance.option();
    if (selectedRowKeys.length) {
      confirmRef.current.instance.option('visible', true);
    }
  };

  const handleDelete = () => {
    const { selectedRowKeys } = ref.current.instance.option();
    api.deleteMany(MODEL.TEMPLATE, selectedRowKeys);
    confirmRef.current.instance.option('visible', false);
  };

  const duplicate = () => {
    const { selectedRowKeys } = ref.current.instance.option();

    if (!selectedRowKeys.length) {
      notification('Debes seleccionar una plantilla', 'error');
      return;
    }

    if (selectedRowKeys.length !== 1) {
      notification('Debes seleccionar solo 1 plantilla', 'error');
      return;
    }

    const template = { ...dataSource.get(selectedRowKeys[0]) };

    delete template._id;
    template.name = `${template.name} (duplicado)`;

    api.create(MODEL.TEMPLATE, template);
  };

  const exportSelectedRows = () => {
    const { selectedRowKeys } = ref.current.instance.option();

    if (!selectedRowKeys.length) {
      notification('Debes seleccionar al menos una plantilla', 'error');
      return;
    }

    const getTemplateJson = ({ template: rawTemplate }) => {
      const template = JSON.parse(JSON.stringify(rawTemplate));

      const getUsedVariables = (items) => {
        const findVars = (item) => {
          if (item.options?.isVariable) {
            const variableId = item.options.variable;
            const variableName = variablesDataSource.get(variableId)?.name;
            item.options.variable = variableName;
          }

          if (item.options?.items) {
            item.options.items.forEach(findVars);
          }
        };

        items.forEach(findVars);
      };

      getUsedVariables(template?.options?.items);

      return JSON.stringify({ name: template.name, options: template.options });
    };

    if (selectedRowKeys.length === 1) {
      const template = dataSource.get(selectedRowKeys[0]);
      const json = getTemplateJson({ template });
      const blob = new Blob([json], { type: 'text/plain;charset=utf-8' });
      FileSaver.saveAs(blob, `${template.name}_${template._id}.json`);
      return;
    }

    const zip = new JSZip();
    for (const key of selectedRowKeys) {
      const template = dataSource.get(key);
      const json = getTemplateJson({ template });
      zip.file(`${template.name}_${template._id}.json`, json);
    }
    zip.generateAsync({ type: 'blob' }).then((content) => {
      FileSaver.saveAs(content, `${establishment.name}.zip`);
    });
  };

  const openImport = () => {
    importPopupRef.current.instance.option('visible', true);
  };

  useEffect(() => {
    let _socket = socket;
    if (!_socket) {
      _socket = io(`${BASE_URL}${establishment._id}`);
      _socket.emit('register-client', _socket.id);
      setSocket(socket);
    }

    _socket.on('printers', setPrinters);

    return () => {
      _socket.off('printers');
      setSocket(null);
      _socket.close();
    };
  }, [establishment, socket]);

  if (template) {
    return (
      <div style={{ display: 'flex', height: '100%', overflow: 'hidden', width: '100%' }}>
        <DocumentEditor template={template} setTemplate={setTemplate} printers={printers} />
      </div>
    );
  }

  return (
    <>
      <DataGrid
        ref={ref}
        height={'100%'}
        keyExpr="_id"
        dataSource={dataSource.list}
        columns={[
          { dataField: 'name', caption: 'Nombre' },
          { dataField: 'establishment', caption: 'Establecimiento', visible: false, showInColumnChooser: false },
          { dataField: 'template', caption: 'Plantilla', visible: false, showInColumnChooser: false },
        ]}
        selection={{
          allowSelectAll: true,
          mode: 'multiple',
        }}
        toolbar={{
          items: [
            { options: { icon: 'add', onClick: handleAdd }, widget: 'dxButton', location: 'before' },
            {
              options: {
                icon: 'refresh',
                onClick: handleRefresh,
              },
              widget: 'dxButton',
              location: 'before',
            },
            {
              options: { icon: 'trash', type: 'danger', stylingMode: 'outlined', onClick: askDelete },
              widget: 'dxButton',
              location: 'before',
            },
            {
              options: { icon: 'copy', text: 'Duplicar', onClick: duplicate },
              widget: 'dxButton',
              location: 'before',
            },
            {
              options: { icon: 'export', text: 'Exportar', onClick: exportSelectedRows },
              widget: 'dxButton',
              location: 'before',
            },
            {
              options: { icon: 'import', text: 'Importar', onClick: openImport },
              widget: 'dxButton',
              location: 'before',
            },
          ],
        }}
        onRowClick={(e) => setTemplate(e.data)}
      />
      <Popup
        ref={confirmRef}
        title="Eliminar"
        height={'auto'}
        width="auto"
        toolbarItems={[
          {
            location: 'center',
            toolbar: 'bottom',
            visible: true,
            widget: 'dxButton',
            options: {
              text: 'Aceptar',
              type: 'danger',
              stylingMode: 'outlined',
              onClick: handleDelete,
            },
          },
        ]}
      >
        <p>¿Realmente desea eliminar los elementos seleccionados?</p>
      </Popup>
      <Popup ref={importPopupRef} title="Importar" height={'auto'} width={400}>
        <FileUploader
          accept=".json"
          stylingMode="outlined"
          uploadMode="useForm"
          showFileList={false}
          multiple={true}
          onValueChanged={async (e) => {
            if (!e.value.length) return;
            const currentVariables = [...variablesDataSource.list];

            const getUsedVariables = (items) => {
              const variables = new Set();
              const findVars = (item) => {
                if (item.options?.isVariable) {
                  variables.add(item.options.variable);
                }

                if (item.options?.items) {
                  item.options.items.forEach(findVars);
                }
              };

              items.forEach(findVars);

              return [...variables];
            };

            const replaceVariables = (items) => {
              const variables = new Set();
              const findVars = (item) => {
                if (item.options?.isVariable) {
                  const variableName = item.options.variable;
                  const variableId = currentVariables.find((x) => x.name === variableName)?._id;

                  item.options.variable = variableId;
                }

                if (item.options?.items) {
                  item.options.items.forEach(findVars);
                }
              };

              items.forEach(findVars);

              return [...variables];
            };

            for (const file of e.value) {
              const json = await getFileJSON(file);
              const usedVariables = getUsedVariables(json.options.items);
              const variablesToCreate = usedVariables.filter(
                (variableName) => !currentVariables.some((currentVariable) => currentVariable.name === variableName)
              );

              for (const name of variablesToCreate) {
                currentVariables.push(await api.create(MODEL.VARIABLE, { name }));
              }

              replaceVariables(json.options.items);

              await api.create(MODEL.TEMPLATE, json);
            }

            e.component.reset();
            importPopupRef.current?.instance.option('visible', false);
            notification('Plantillas importadas', 'success');
          }}
        />
      </Popup>
    </>
  );
};

const getFileJSON = async (file) => {
  return new Promise((resolve, reject) => {
    var reader = new FileReader();
    reader.readAsText(file, 'UTF-8');
    reader.onload = function (evt) {
      resolve(JSON.parse(evt.target.result));
    };

    reader.onerror = function (err) {
      reject(err);
    };
  });
};
