import notify from 'devextreme/ui/notify';
import html2canvas from 'html2canvas';
import { useEditor } from '.';
import { getDefaultItemConfig, ONE_PIXEL_IN_MM } from './constants';

export const useDropProps = () => {
  const editor = useEditor();

  return {
    onDrop: handleDrop(editor),
    onDragOver: handleDragOver,
    onDragEnter: handleToggleContainer,
    onDragLeave: handleToggleContainer,
  };
};

const handleDrop = (editor) => (e) => {
  const { update, getConfig, deletePath } = editor;
  e.preventDefault();
  e.stopPropagation();

  e.target.classList.toggle('drag-hover', e.type === 'dragenter');

  if (!e.target.classList.contains('drop-area')) return;

  const toPath = e.target.getAttribute('data-path');
  const action = e.dataTransfer.getData('action');

  let path = toPath;
  let items = getConfig(path);

  if (!items) return;
  if (!Array.isArray(items)) {
    path = `${path}.options.items`;
    items = getConfig(path);
  }

  if (action === 'ADD') {
    const itemType = e.dataTransfer.getData('itemType');

    const newItem = getDefaultItemConfig(itemType);

    update(path, [...items, newItem]);
  } else if (action === 'MOVE') {
    const currentItemPath = e.dataTransfer.getData('item');
    const currentItem = getConfig(currentItemPath);

    const isSamePath = toPath === currentItemPath;
    const isTargetAChild = toPath.split(currentItemPath).length > 1;

    if (isSamePath || isTargetAChild) {
      notification('Movimiento inválido');
      return;
    }

    update(path, [...items, currentItem]);
    deletePath(currentItemPath);
  }
};

const handleDragOver = (e) => {
  e.preventDefault();
  e.stopPropagation();
};

const handleToggleContainer = (e) => {
  e.preventDefault();
  e.target.classList.toggle('drag-hover', e.type === 'dragenter');
};

const handleDragStart = (e) => {
  if (e.target === document.activeElement) {
    e.preventDefault();
    return;
  }
  e.dataTransfer.setData('action', 'MOVE');
  e.dataTransfer.setData('item', e.target.getAttribute('data-path'));
};

export const dragProps = {
  draggable: 'true',
  onDragStart: handleDragStart,
  onDragOver: handleDragOver,
};

export function updateObj(object, path, newValue) {
  var stack = path.split('.');

  while (stack.length > 1) {
    let step = getStep(stack.shift());

    if (!object[step]) {
      object[step] = {};
    }

    object = object[step];
  }

  const lastStep = getStep(stack.shift());

  object[lastStep] = newValue;
}

export function deleteProp(object, path) {
  var stack = path.split('.');

  while (stack.length > 1) {
    let step = getStep(stack.shift());

    object = object[step];
  }
  const lastStep = getStep(stack.shift());
  if (Array.isArray(object)) {
    object.splice(lastStep, 1);
    return;
  }

  delete object[lastStep];
}

const getStep = (step) => {
  if (step.match(/\[\d+\]/)) {
    step = Number(step.replace(/[[\]]/g, ''));
  }

  return step;
};

export const pxToMm = (px) => {
  return (px * ONE_PIXEL_IN_MM).toFixed(2);
};

export const getResult = async (element, { height, width }) => {
  return new Promise((resolve, reject) => {
    html2canvas(element)
      .then(function (canvas) {
        var data = canvas.toDataURL();
        resolve(data);
      })
      .catch(reject);
  });
};

export const notification = (message, type) =>
  notify(
    {
      message,
      width: 'auto',
      animation: {
        show: { type: 'fade', duration: 400, from: 0, to: 1 },
        hide: { type: 'fade', duration: 400, from: 1, to: 0 },
      },
      closeOnClick: true,
      position: { at: 'center top', offset: { x: 0, y: 100 } },
      type,
      displayTime: 2 * 1000,
    },
    {
      position: 'top center',
      direction: 'down-stack',
    }
  );
