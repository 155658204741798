export const DefaultVariables = [
  'ID',
  'Cross ReferenceDUN14',
  'Description',
  'Description2',
  'Item No.',
  'Line',
  'Documento',
  'Format',
  'SeriesNo',
  'Weight',
  'WhClass',
  'BulkNo',
  'Date',
  'ShipToAdress',
  'Boxes',
  'Width',
  'User ID',
  'Length',
  'Height',
  'Qty. Lot',
  'Customer',
  'CustomerName',
  'SalesOrder',
  'QUM',
  'SourceName',
  'RecQty',
  'ToBin',
  'Date and Time',
  'FromBin',
  'Quantity',
  'UM',
  'QRCode',
  'LotNo',
  'SourceContry',
  'Printer',
  'Comunicated',
  'Copies',
  'Cross ReferenceEAN13',
  'Nº Versión producto',
  'Campo libre 1',
  'Campo libre 2',
  'Campo libre 3',
  'Campo libre 4',
  'Campo libre 5',
  'Campo libre 6',
  'Campo libre 7',
  'Campo libre 8',
  'Campo libre 9',
  'Campo libre 10',
];
