import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import themes from 'devextreme/ui/themes';
import './index.css';
import axios from 'axios';
import { BASE_URL } from './constants/api';

themes.current(window.localStorage.getItem('dx-theme') || 'dx.light.kuara');

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

axios.defaults.baseURL = BASE_URL;
