import { NumberBox } from 'devextreme-react';
import { useEditor } from '.';

export const Size = () => {
  const { size, template, update } = useEditor();
  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: 8,
          alignSelf: 'center',
          padding: 8,
        }}
      >
        <NumberBox
          label="Base (mm)"
          value={template.options.width}
          onValueChange={(value) => update('options.width', value)}
        />
        <NumberBox
          label="Altura (mm)"
          value={template.options.height}
          onValueChange={(value) => update('options.height', value)}
        />
        <div>
          Tamaño actual: {size.width}mm x {size.height}mm
        </div>
      </div>
    </>
  );
};
