import { DataGrid, Popup } from 'devextreme-react';
import { useRef } from 'react';
import { MODEL } from '../constants/models';
import { useAPI, useDataSource } from '../store';
import { DefaultVariables } from '../constants/defaultVariables';
import { notification } from './Templates/DocumentEditor/utils';

export default function Variables() {
  const ref = useRef();
  const confirmRef = useRef();
  const api = useAPI();

  const dataSource = useDataSource(MODEL.VARIABLE).list;

  const askDelete = () => {
    const { selectedRowKeys } = ref.current.instance.option();
    if (selectedRowKeys.length) {
      confirmRef.current.instance.option('visible', true);
    }
  };

  const handleDelete = () => {
    const { selectedRowKeys } = ref.current.instance.option();
    api.deleteMany(MODEL.VARIABLE, selectedRowKeys);
    confirmRef.current.instance.option('visible', false);
  };

  const handleRefresh = () => {
    api.fetchAll(MODEL.VARIABLE);
  };

  const handleInsert = (e) => {
    e.cancel = true;
    api.create(MODEL.VARIABLE, e.data);
  };

  const handleUpdate = (e) => {
    e.cancel = true;
    api.updateById(MODEL.VARIABLE, { ...e.oldData, ...e.newData });
  };

  const handleDeleteRow = (e) => {
    e.cancel = true;
    api.deleteMany(MODEL.VARIABLE, [e.key]);
  };

  const loadBCVars = async () => {
    notification('Cargando variables...', 'info');
    const defaultVariables = DefaultVariables.filter(
      (defaultVariableName) => !dataSource.some(({ name }) => defaultVariableName === name)
    );
    for (const name of defaultVariables) {
      await api.create(MODEL.VARIABLE, { name });
    }

    notification('Variables cargadas', 'success');
  };

  return (
    <>
      <DataGrid
        ref={ref}
        height={'100%'}
        keyExpr="_id"
        dataSource={dataSource}
        columns={[
          { dataField: 'name', caption: 'Nombre' },
          { dataField: 'defaultValue', caption: 'Valor por defecto' },
          { dataField: 'establishment', caption: 'Establecimiento', visible: false, showInColumnChooser: false },
        ]}
        selection={{
          allowSelectAll: true,
          mode: 'multiple',
        }}
        editing={{
          allowAdding: true,
          allowUpdating: true,
          allowDeleting: true,
          useIcons: true,
        }}
        paging={{
          pageSize: 50,
        }}
        pager={{
          visible: true,
          showInfo: true,
        }}
        onRowInserting={handleInsert}
        onRowUpdating={handleUpdate}
        onRowRemoving={handleDeleteRow}
        toolbar={{
          items: [
            { name: 'addRowButton', location: 'before' },
            {
              options: {
                icon: 'refresh',
                onClick: handleRefresh,
              },
              widget: 'dxButton',
              location: 'before',
            },
            {
              options: { icon: 'trash', type: 'danger', stylingMode: 'outlined', onClick: askDelete },
              widget: 'dxButton',
              location: 'before',
            },
            {
              options: { icon: 'import', text: 'Cargar variables integración BC', onClick: loadBCVars },
              widget: 'dxButton',
              location: 'before',
            },
          ],
        }}
      />
      <Popup
        ref={confirmRef}
        title="Eliminar"
        height={'auto'}
        width="auto"
        toolbarItems={[
          {
            location: 'center',
            toolbar: 'bottom',
            visible: true,
            widget: 'dxButton',
            options: {
              text: 'Aceptar',
              type: 'danger',
              stylingMode: 'outlined',
              onClick: handleDelete,
            },
          },
        ]}
      >
        <p>¿Realmente desea eliminar los elementos seleccionados?</p>
      </Popup>
    </>
  );
}
