import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  gap: 8px;
  padding: 8px;
  align-items: end;
  flex-wrap: wrap;
`;

export const DraggableItem = styled.div`
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 16px;
  font-family: 'Charlie Display', 'Segoe UI', helvetica, verdana, sans-serif;
  cursor: grab;
  display: flex;
  gap: 8px;
  align-items: center;
  font-size: 14px;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 7px 18px 8px;

  i {
    font-size: 18px;
  }

  :active {
    cursor: grabbing;
  }
`;
