import { DropDownButton } from 'devextreme-react';
import { MODEL } from '../../constants/models';
import { useData } from '../../store';
import { setCookie } from '../../utils';
import { useIsMobile } from '../../utils/hooks';

export const ProfileButton = ({ isAuth }) => {
  const isMobile = useIsMobile();
  const profile = useData(MODEL.PROFILE);

  const logout = () => {
    setCookie('remember', false);
    window.location.reload();
  };

  if (isMobile || !isAuth) {
    return <></>;
  }

  const dropdownItems = [{ key: 'logout', label: 'Cerrar sesión', icon: 'runner', onClick: logout }];

  return (
    <DropDownButton
      text={`${profile?.name ?? ''} ${profile?.surname ?? ''}`}
      icon="user"
      displayExpr={'label'}
      stylingMode="text"
      className="white-text"
      dropDownOptions={dropdownOptions}
      items={dropdownItems}
    />
  );
};

const dropdownOptions = { width: '150px' };
