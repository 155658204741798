import { TextBox } from 'devextreme-react';
import { useEditor } from '.';
import { MODEL } from '../../../constants/models';
import { useDataSource } from '../../../store';

const getUsedVariables = (items) => {
  const output = [];

  const findVars = (item) => {
    if (item.options?.isVariable) {
      output.push(item.options.variable);
    }

    if (item.options?.items) {
      item.options.items.forEach(findVars);
    }
  };

  items.forEach(findVars);

  return [...new Set(output).keys()];
};

export const Variables = () => {
  const { template, keyValues, setKeyValues } = useEditor();
  const usedVars = getUsedVariables(template?.options?.items);
  const allVariables = useDataSource(MODEL.VARIABLE).list.filter((x) => usedVars.includes(x._id));

  const updateKeyValue = (key) => (value) => {
    setKeyValues((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  return (
    <div
      style={{
        padding: 8,
        overflow: 'hidden auto',
        minWidth: 150,
        borderRight: '1px solid #ccc',
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
      }}
    >
      <h1 className="section-title">Variables</h1>
      {allVariables.map((x) => {
        return (
          <TextBox
            key={x._id}
            label={x.name}
            value={keyValues[x.name] ?? x.defaultValue}
            onValueChange={updateKeyValue(x.name)}
          />
        );
      })}
    </div>
  );
};
