import { Form, LoadPanel } from 'devextreme-react';
import { LoginFormWrapper } from '../components/styled';
import { FormFields } from '../constants/items';
import { useCallback, useEffect, useState } from 'react';
import { getCookie, setCookie } from '../utils';
import notify from 'devextreme/ui/notify';
import axios from 'axios';
import { useAPI } from '../store';
import { MODEL } from '../constants/models';

export const Login = (props) => {
  const api = useAPI();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState();

  const loadData = useCallback(async () => {
    api.set(MODEL.PROFILE, await fetcher('/user/profile'));
    api.set(MODEL.ESTABLISHMENT, await fetcher('/establishment'));
    api.set(MODEL.TEMPLATE, await fetcher('/template'));
    api.set(MODEL.VARIABLE, await fetcher('/variable'));
  }, [api]);

  const login = useCallback(
    async ({ username, password }, remember) => {
      setLoading(false);
      return await axios
        .post('/user/login', { username, password })
        .then(({ data }) => {
          if (remember) {
            setCookie('remember', remember);
            setCookie('username', username);
            setCookie('password', password);
          }

          axios.defaults.headers.common = {
            Authorization: `Bearer ${data.access_token}`,
          };

          loadData();

          return true;
        })
        .catch(() => {
          notify('Error al iniciar sesión', 'error');
          return false;
        })
        .finally(() => setLoading(false));
    },
    [loadData]
  );

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    const form = new FormData(e.target);
    const { rememberMe, ...data } = Object.fromEntries(form);

    await login(data, rememberMe);
  };

  useEffect(() => {
    const tryLogin = async () => {
      if (loading) return;

      const rememberMe = getCookie('remember') === 'true';

      if (!rememberMe) return;

      const [username, password] = [getCookie('username'), getCookie('password')];
      setFormData((prev) => ({ username, password, rememberMe, ...prev }));

      setLoading(true);
      const result = await login({ username, password });
      if (!result) {
        setCookie('remember', 'false');
      }
      setLoading(false);
    };

    tryLogin();
  }, [loading, login]);

  return (
    <LoginFormWrapper>
      <LoadPanel
        visible={loading}
        message={'Autenticando...'}
        showIndicator={true}
        showPane={true}
        shading={true}
        animation={{ show: { type: 'pop' } }}
        shadingColor="rgba(0, 0, 0, 0.1)"
      />
      <form onSubmit={handleSubmit}>
        <Form labelMode="floating" formData={formData} items={formItems} />
      </form>
    </LoginFormWrapper>
  );
};

const formItems = [FormFields.Username, FormFields.Password, FormFields.RememberMe, FormFields.Submit];

const fetcher = async (url) => {
  return await axios
    .get(url)
    .then(({ data }) => data)
    .catch(() => null);
};
