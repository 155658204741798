import {
  Button,
  CheckBox,
  ColorBox,
  FileUploader,
  NumberBox,
  Popup,
  RadioGroup,
  SelectBox,
  TextBox,
} from 'devextreme-react';
import { useEditor } from '.';
import { MODEL } from '../../../constants/models';
import { useDataSource } from '../../../store';
import { FORMATS } from './constants';
import { notification } from './utils';

export const ElementForm = () => {
  const { editingPath, setEditingPath, getConfig, update, deletePath } = useEditor();
  const allVariables = useDataSource(MODEL.VARIABLE).list;
  const element = getConfig(editingPath);
  const options = element?.options ?? {};
  const barcodeOptions = element?.barcodeOptions ?? {};
  const style = options.style ?? {};

  const handleChange = (path, measure) => (value) => {
    let output = value ? (measure ? `${value}${measure}` : value) : undefined;
    update(`${editingPath}.options.${path}`, output);
  };

  const updateBarcodeOption = (path, value) => {
    update(`${editingPath}.barcodeOptions.${path}`, value);
  };

  return (
    <Popup
      title="Propiedades"
      visible={Boolean(editingPath)}
      onHiding={() => setEditingPath()}
      shading={false}
      height="auto"
      minWidth={250}
      maxWidth={400}
      maxHeight={'100%'}
      width="auto"
      wrapperAttr={{ id: 'element-props-form' }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
        {element?.itemType === 'text' ? (
          <>
            <div style={{ display: 'flex', gap: 8 }}>
              {options.isVariable ? (
                <SelectBox
                  value={options.variable}
                  label="Variable"
                  style={{ flexGrow: 1 }}
                  dataSource={allVariables}
                  displayExpr="name"
                  valueExpr={'_id'}
                  onValueChange={handleChange('variable')}
                />
              ) : (
                <TextBox
                  value={options.text}
                  style={{ flexGrow: 1 }}
                  label={'Texto'}
                  onValueChange={handleChange('text')}
                />
              )}
              <CheckBox
                value={options.isVariable ?? false}
                text="Variable"
                onValueChange={handleChange('isVariable')}
              />
            </div>
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 8 }}>
              <TextBox
                value={options.style.width?.toString().replace('mm', '')}
                label={'Base (mm)'}
                onValueChange={handleChange('style.width', 'mm')}
              />
              <TextBox
                value={options.style.height?.toString().replace('mm', '')}
                label={'Altura (mm)'}
                onValueChange={handleChange('style.height', 'mm')}
              />
            </div>
            <CheckBox
              value={options.style?.fontWeight === 'bold'}
              text="Negrita"
              onValueChange={(value) => {
                update(`${editingPath}.options.style.fontWeight`, value ? 'bold' : 'normal');
              }}
            />
            <NumberBox value={style.fontSize} label={'Tamaño (px)'} onValueChange={handleChange('style.fontSize')} />
          </>
        ) : element?.itemType === 'image' ? (
          <>
            <TextBox
              value={options.src}
              label={'URL'}
              onValueChange={(value) => {
                fetch(value)
                  .then(async (response) => {
                    var reader = new FileReader();
                    reader.readAsDataURL(await response.blob());
                    reader.onloadend = function () {
                      handleChange('base64')(reader.result);
                    };
                  })
                  .catch(() => {
                    handleChange('base64')('');
                    notification('No se ha podido cargar la imágen. Prueba con otra', 'error');
                  });
                handleChange('src')(value);
              }}
            />

            <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
              {options.base64 ? (
                <img src={options.base64} alt="" style={{ height: 128, width: 128, objectFit: 'contain' }} />
              ) : null}
              <FileUploader
                labelText=""
                accept="image/*"
                uploadMode="useForm"
                onValueChange={async ([file]) => {
                  if (file) {
                    const base64 = await readFileAsDataURL(file);

                    handleChange('src')('');
                    handleChange('base64')(base64);
                  }
                }}
              />
            </div>
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 8 }}>
              <NumberBox value={options.width} label={'Base (px)'} onValueChange={handleChange('width')} />
              <NumberBox value={options.height} label={'Altura (px)'} onValueChange={handleChange('height')} />
            </div>
          </>
        ) : element?.itemType === 'qr' ? (
          <>
            <div style={{ display: 'flex', gap: 8 }}>
              {options.isVariable ? (
                <SelectBox
                  value={options.variable}
                  label="Variable"
                  style={{ flexGrow: 1 }}
                  dataSource={allVariables}
                  displayExpr="name"
                  valueExpr={'_id'}
                  onValueChange={handleChange('variable')}
                />
              ) : (
                <TextBox
                  value={options.value}
                  style={{ flexGrow: 1 }}
                  label={'Valor'}
                  onValueChange={handleChange('value')}
                />
              )}
              <CheckBox
                value={options.isVariable ?? false}
                text="Variable"
                onValueChange={handleChange('isVariable')}
              />
            </div>
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 8 }}>
              <NumberBox value={options.width} label={'Base (px)'} onValueChange={handleChange('width')} />
              <NumberBox value={options.height} label={'Altura (px)'} onValueChange={handleChange('height')} />
            </div>
          </>
        ) : element?.itemType === 'box' ? (
          <>
            <label>Orientación</label>
            <RadioGroup
              layout="horizontal"
              value={options.style.flexDirection}
              items={[
                { label: 'Vertical', value: 'column' },
                { label: 'Horizontal', value: 'row' },
              ]}
              displayExpr="label"
              valueExpr={'value'}
              onValueChange={handleChange('style.flexDirection')}
            />

            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 8 }}>
              <TextBox
                value={options.style.width?.toString().replace('mm', '')}
                label={'Base (mm)'}
                onValueChange={handleChange('style.width', 'mm')}
              />
              <TextBox
                value={options.style.height?.toString().replace('mm', '')}
                label={'Altura (mm)'}
                onValueChange={handleChange('style.height', 'mm')}
              />
            </div>

            <br />
            <label>Alinear objetos</label>
            <RadioGroup
              layout="horizontal"
              value={options.style.alignItems}
              items={[
                { label: 'Inicio', value: 'start' },
                { label: 'Centro', value: 'center' },
                { label: 'Final', value: 'end' },
              ]}
              displayExpr="label"
              valueExpr={'value'}
              onValueChange={handleChange('style.alignItems')}
            />
            <ColorBox
              label="Color de fondo"
              value={style.background}
              onValueChange={handleChange('style.background')}
            />
            <ColorBox label="Color de texto" value={style.color} onValueChange={handleChange('style.color')} />
          </>
        ) : element?.itemType === 'barcode' ? (
          <>
            <div style={{ display: 'flex', gap: 8 }}>
              {options.isVariable ? (
                <SelectBox
                  value={options.variable}
                  label="Variable"
                  style={{ flexGrow: 1 }}
                  dataSource={allVariables}
                  displayExpr="name"
                  valueExpr={'_id'}
                  onValueChange={handleChange('variable')}
                />
              ) : (
                <TextBox
                  value={options.value}
                  style={{ flexGrow: 1 }}
                  label={'Valor'}
                  onValueChange={handleChange('value')}
                />
              )}
              <CheckBox
                value={options.isVariable ?? false}
                text="Variable"
                onValueChange={handleChange('isVariable')}
              />
            </div>
            <SelectBox
              label="Tipo"
              dataSource={FORMATS.map((x) => x.value)}
              value={barcodeOptions.format}
              onValueChange={(value) => {
                updateBarcodeOption('format', value);

                if (!['CODE128', 'CODE128A', 'CODE128B', 'CODE128C'].includes(value) && barcodeOptions.ean128)
                  updateBarcodeOption('ean128', false);
                if (!['UPC', 'EAN13', 'EAN8'].includes(value) && barcodeOptions.flat)
                  updateBarcodeOption('flat', false);
                if (value !== 'CODE39' && barcodeOptions.mod43) handleChange('mod43', false);
              }}
            />
            <p>{FORMATS.find((x) => x.value === barcodeOptions.format)?.description}</p>
            {['CODE128', 'CODE128A', 'CODE128B', 'CODE128C'].includes(barcodeOptions.format) ? (
              <CheckBox
                value={barcodeOptions.ean128}
                onValueChange={(value) => updateBarcodeOption('ean128', value)}
                text="Habilite la codificación CODE128 como GS1-128/EAN-128."
              />
            ) : ['UPC', 'EAN13', 'EAN8'].includes(barcodeOptions.format) ? (
              <CheckBox
                value={barcodeOptions.flat}
                onValueChange={(value) => updateBarcodeOption('flat', value)}
                text={`Los códigos de barras EAN-13, EAN-8 y UPC se usan con mayor frecuencia con "barras de protección". Si no desea estos y, en cambio, una representación plana, puede especificar esta opción y omitirlas.`}
              />
            ) : barcodeOptions.format === 'CODE39' ? (
              <CheckBox
                value={barcodeOptions.mod43}
                onValueChange={(value) => updateBarcodeOption('mod43', value)}
                text="CODE39 a veces se usa con un dígito de control de módulo 43 opcional. Su uso requiere que esta función esté habilitada en el lector de código de barras. Se habilita con este campo en verdadero."
              />
            ) : null}
            <CheckBox
              text="Mostrar valor"
              value={barcodeOptions.displayValue}
              onValueChange={(value) => updateBarcodeOption('displayValue', value)}
            />
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 8 }}>
              <NumberBox
                label="Base"
                min={0}
                value={barcodeOptions.width}
                onValueChange={(value) => updateBarcodeOption('width', value)}
              />
              <NumberBox
                label="Altura"
                min={0}
                value={barcodeOptions.height}
                onValueChange={(value) => updateBarcodeOption('height', value)}
              />
            </div>
            <label>Alineación del texto</label>
            <RadioGroup
              value={barcodeOptions.textAlign}
              layout="horizontal"
              onValueChange={(value) => updateBarcodeOption('textAlign', value)}
              dataSource={[
                { text: 'Izquierda', value: 'left' },
                { text: 'Centro', value: 'center' },
                { text: 'Derecha', value: 'right' },
              ]}
              valueExpr="value"
              displayExpr="text"
            />
            <label>Posición del texto</label>
            <RadioGroup
              layout="horizontal"
              value={barcodeOptions.textPosition}
              onValueChange={(value) => updateBarcodeOption('textPosition', value)}
              dataSource={[
                { text: 'Arriba', value: 'top' },
                { text: 'Abajo', value: 'bottom' },
              ]}
              valueExpr="value"
              displayExpr="text"
            />
            <NumberBox
              label="Tamaño del texto"
              value={barcodeOptions.fontSize}
              onValueChange={(value) => updateBarcodeOption('fontSize', value)}
            />
            <ColorBox
              label="Color del text"
              value={barcodeOptions.lineColor}
              onValueChange={(value) => updateBarcodeOption('lineColor', value)}
            />
            <ColorBox
              label="Color de fondo"
              value={barcodeOptions.background}
              onValueChange={(value) => updateBarcodeOption('background', value)}
            />
          </>
        ) : (
          <p>No hay elemento seleccionado</p>
        )}

        {element?.itemType !== 'barcode' ? (
          <>
            <ColorBox
              label="Color de borde"
              value={style.borderColor}
              onValueChange={handleChange('style.borderColor')}
            />
            <SelectBox
              value={style.borderStyle}
              label="Tipo de borde"
              dataSource={[
                { value: 'none', label: 'Sin borde' },
                { value: 'solid', label: 'Simple' },
                { value: 'dotted', label: 'Puntos' },
                { value: 'dashed', label: 'Discontinuo' },
                { value: 'double', label: 'Doble' },
              ]}
              displayExpr={'label'}
              valueExpr={'value'}
              onValueChange={handleChange('style.borderStyle')}
            />
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 8 }}>
              <NumberBox
                value={style.borderRadius}
                label={'Esquinas (px)'}
                onValueChange={handleChange('style.borderRadius')}
              />
              <NumberBox
                value={style.borderWidth}
                label={'Grosor borde (px)'}
                onValueChange={handleChange('style.borderWidth')}
              />
            </div>
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 8 }}>
              <NumberBox
                value={style.marginTop}
                label={'Margen superior (px)'}
                onValueChange={handleChange('style.marginTop')}
              />
              <NumberBox
                value={style.marginBottom}
                label={'Margen inferior (px)'}
                onValueChange={handleChange('style.marginBottom')}
              />
            </div>
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 8 }}>
              <NumberBox
                value={style.marginLeft}
                label={'Margen izquierdo (px)'}
                onValueChange={handleChange('style.marginLeft')}
              />
              <NumberBox
                value={style.marginRight}
                label={'Margen derecho (px)'}
                onValueChange={handleChange('style.marginRight')}
              />
            </div>
          </>
        ) : null}
        <Button
          text="Eliminar"
          icon="trash"
          type="danger"
          stylingMode="outlined"
          onClick={() => {
            deletePath(editingPath);
            setEditingPath();
          }}
        />
      </div>
    </Popup>
  );
};

const readFileAsDataURL = async (file) => {
  return await new Promise((resolve) => {
    let fileReader = new FileReader();
    fileReader.onload = (e) => resolve(fileReader.result);
    fileReader.readAsDataURL(file);
  });
};
