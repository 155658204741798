import { ProfileButton } from './ProfileButton';
import { useCurrentPathTitle } from '../../utils/hooks';
import { AppHeaderWrapper } from '../styled';
import { useData } from '../../store';
import { MODEL } from '../../constants/models';

export const AppHeader = () => {
  const isAuth = useData(MODEL.PROFILE);
  const title = useCurrentPathTitle();

  return (
    <AppHeaderWrapper>
      <h2>{title}</h2>
      <ProfileButton isAuth={isAuth} />
    </AppHeaderWrapper>
  );
};
