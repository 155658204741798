export const keyBy = (data) => {
  const output = {};

  if (Array.isArray(data)) {
    data.forEach((item) => {
      output[item._id] = item;
    });
  } else if (typeof data === "object") {
    output[data._id] = data;
  }

  return output;
};

export const setCookie = (name, value, days) => {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
};

export const getCookie = (key) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${key}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
};

export const deleteCookie = async (key) => {
  document.cookie = key + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
};
