import { TreeView } from 'devextreme-react';
import { useCallback } from 'react';
import { useNavigate } from 'react-router';
import { MODEL } from '../../constants/models';
import { useData } from '../../store';
import { AppMenuWrapper } from '../styled';

export const AppMenu = () => {
  const isAuth = useData(MODEL.PROFILE);

  const navigate = useNavigate();

  const handleClickItem = useCallback(
    (event) => {
      const { component, node, itemElement: item, itemData: data } = event;
      const isExpanded = node.expanded;
      const { path } = data;

      if (path) {
        navigate(path);
        const id = 'app-menu-selected';
        const currentSelected = document.getElementById(id);

        if (currentSelected) {
          currentSelected.id = undefined;
        }

        item.id = id;
      } else {
        if (isExpanded) {
          component.collapseItem(item);
        } else {
          component.expandItem(item);
        }
      }
    },
    [navigate]
  );

  if (!isAuth) {
    return <></>;
  }

  return (
    <AppMenuWrapper>
      <TreeView
        focusStateEnabled={false}
        activeStateEnabled={false}
        items={items}
        displayExpr="label"
        keyExpr={'key'}
        onItemClick={handleClickItem}
      />
    </AppMenuWrapper>
  );
};

const items = [
  { label: 'Plantillas', path: '/templates', icon: 'doc', key: 'templates' },
  { label: 'Variables', path: '/variables', icon: 'key', key: 'variables' },
];
