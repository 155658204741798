import { Button, Popup, TextBox } from 'devextreme-react';
import { useRef } from 'react';
import { useEditor } from '.';
import { MODEL } from '../../../constants/models';
import { useAPI } from '../../../store';
import { Container } from './Components';
import { getResult, notification } from './utils';

export const Toolbar = () => {
  const api = useAPI();
  const confirmRef = useRef();
  const { template, setTemplate, update, previewRef, setPreviewImage, setPrinting, size } = useEditor();

  const handleSave = async () => {
    return await (template._id ? api.updateById : api.create)(MODEL.TEMPLATE, template).then((data) => {
      notification('Plantilla guardada', 'success');
      return data;
    });
  };

  const handleDelete = async () => {
    if (template?._id) {
      await api.deleteMany(MODEL.TEMPLATE, [template._id]);
    }
    setTemplate();
  };

  const handleDownload = async () => {
    const base64 = await getResult(previewRef.current, size);

    if (!base64) return;
    var a = document.createElement('a');
    a.href = base64;
    a.download = `${template.name}.png`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const previewPrinting = async () => {
    const image = await getResult(previewRef.current, size);
    setPreviewImage(image);
    setPrinting(true);
  };

  return (
    <>
      <Container style={{ borderBottom: '1px solid #ccc' }}>
        <Button type="normal" icon="chevronleft" onClick={() => setTemplate()} />
        <TextBox label="Nombre" value={template.name} onValueChange={(value) => update('name', value)} />
        <Button type="default" icon="save" text="Guardar" onClick={handleSave} />
        <Button
          type="danger"
          stylingMode="outlined"
          icon="trash"
          text="Eliminar"
          onClick={() => confirmRef.current.instance.option('visible', true)}
        />
        <Button icon="print" text="Imprimir" onClick={previewPrinting} />
        <Button icon="download" text="Descargar" onClick={handleDownload} />
      </Container>
      <Popup
        ref={confirmRef}
        title="Eliminar"
        height={'auto'}
        width={'auto'}
        toolbarItems={[
          {
            widget: 'dxButton',
            toolbar: 'bottom',
            location: 'center',
            options: {
              text: 'Aceptar',
              stylingMode: 'outlined',
              type: 'danger',
              onClick: handleDelete,
            },
          },
        ]}
      >
        ¿Realmente desea eliminar la plantilla?
      </Popup>
    </>
  );
};
