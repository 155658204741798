import axios from 'axios';
import { NumberBox, Popup, SelectBox } from 'devextreme-react';
import { nanoid } from 'nanoid';
import { useEffect, useState } from 'react';
import { useEditor } from '.';

export const PrintingPreview = () => {
  const { printing, setPrinting, previewImage, setPreviewImage, size, printers } = useEditor();
  const [codesPerPage, setCodesPerPage] = useState(0);
  const [padding, setPadding] = useState({ paddingBlock: 20, paddingInline: 15 });
  const [maxCopies, setMaxCopies] = useState(1);
  const [copies, setCopies] = useState(1);
  const [columns, setColumns] = useState(1);
  const [printer, setPrinter] = useState('');

  const close = () => {
    setPrinting(false);
    setPreviewImage(null);
  };

  useEffect(() => {
    const maxWidth = 210 - padding.paddingInline * 2;
    const maxHeight = 297 - padding.paddingBlock * 2;

    const imgWidth = Number(size.width);
    const horizontal = maxWidth / imgWidth;
    const maxHorizontal = Math.floor(horizontal);

    const imgHeight = Number(size.height);
    const vertical = maxHeight / imgHeight;
    const maxVertical = Math.floor(vertical);

    const maxCopies = maxHorizontal * maxVertical;
    const total = maxCopies > copies ? copies : maxCopies;
    setCodesPerPage(total === Infinity || isNaN(total) || total < 0 ? 0 : total);
    setColumns(maxHorizontal);
    setMaxCopies(maxCopies < 0 ? 0 : maxCopies);
  }, [size.width, size.height, padding, copies]);

  const images = Array.from(Array(codesPerPage)).map((x, index) => (
    <img key={index} src={previewImage} style={{ display: 'block', ...size }} alt="" />
  ));

  const pages = Math.floor(copies / maxCopies) || 1;
  const restCopies = copies % (copies < maxCopies ? copies : maxCopies);

  const handlePrint = async () => {
    if (!printer) {
      return;
    }
    const getBody = (copies) => {
      return {
        _id: nanoid(24),
        model: 'barcode',
        copies,
        printers: [printer],
        bodyStyle: `display: flex;flex-wrap: wrap; align-content: start; padding: ${padding.paddingBlock}mm ${padding.paddingInline}mm`,
        element: `<img style="width: ${size.width}mm; height: ${size.height}mm" src="${previewImage}"/>`,
      };
    };
    for (let i = 0; i < pages; i++) {
      await axios.post('/barcode/print', getBody(codesPerPage));
    }

    if (restCopies) {
      axios.post('/barcode/print', getBody(restCopies));
    }
  };

  return (
    <Popup
      title="Impresión"
      fullScreen={true}
      visible={printing}
      onHiding={close}
      toolbarItems={[
        {
          toolbar: 'bottom',
          location: 'after',
          widget: 'dxButton',
          options: {
            text: 'Imprimir',
            icon: 'print',
            onClick: handlePrint,
          },
        },
      ]}
    >
      <div style={{ display: 'flex', gap: 8, overflow: 'hidden auto', height: '100%' }}>
        <div style={{ flexBasis: 300 }}>
          <h1>Opciones</h1>
          <NumberBox value={copies} label="Nº copias" onValueChange={setCopies} />
          <NumberBox
            value={padding.paddingInline}
            label="Area segura laterales (mm)"
            onValueChange={(value) => setPadding((prev) => ({ ...prev, paddingInline: value }))}
          />
          <NumberBox
            value={padding.paddingBlock}
            label="Area segura superior e inferior (mm)"
            onValueChange={(value) => setPadding((prev) => ({ ...prev, paddingBlock: value }))}
          />
          <SelectBox
            value={printer}
            onValueChange={setPrinter}
            label="Impresora"
            dataSource={printers}
            displayExpr="name"
            valueExpr={'name'}
          />
          <br />
          <div>
            {pages} página(s) con {codesPerPage} código(s)
          </div>
          {restCopies > 0 ? <div>1 página con {restCopies} código(s)</div> : null}
        </div>
        <div
          style={{
            border: '1px solid black',
            height: '297mm',
            width: '210mm',
            scale: '0.5',
            overflow: 'auto',
            transformOrigin: 'top',
            paddingBlock: `${padding.paddingBlock}mm`,
            paddingInline: `${padding.paddingInline}mm`,
            display: 'grid',
            alignContent: 'start',
            gridTemplateColumns: `repeat(${columns}, ${size.width}mm)`,
          }}
        >
          {images}
        </div>
      </div>
    </Popup>
  );
};
