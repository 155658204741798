import JsBarcode from 'jsbarcode';
import { nanoid } from 'nanoid';
import QRCode from 'qrcode';
import { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useEditor } from '.';
import { dragProps, notification, pxToMm, useDropProps } from './utils';
import { useDataSource } from '../../../store';
import { MODEL } from '../../../constants/models';

export const BarcodeContainer = () => {
  const { template = {}, setEditingPath, setSize, setPreviewRef, keyValues } = useEditor();
  const allVariables = useDataSource(MODEL.VARIABLE).list;

  const { options = {} } = template;
  const { width = 400, height = 400, items = [] } = options;

  const previewRef = useRef();

  useEffect(() => {
    const barcodes = document.getElementsByClassName('display-barcode');

    for (const barcode of barcodes) {
      const options = JSON.parse(barcode.getAttribute('data-config'));
      const isVariable = barcode.getAttribute('is-variable') === 'true';
      const variable = allVariables.find((x) => x._id === barcode.getAttribute('variable'));
      const value =
        (isVariable ? keyValues[variable?.name] ?? variable?.defaultValue : barcode.getAttribute('value')) ?? '12345';
      try {
        JsBarcode(`#${barcode.id}`, value, options);
      } catch (e) {
        notification('El código introducido en el código de barras es inválido', 'error');
      }
    }

    const qrs = document.getElementsByClassName('display-qr');

    for (const qr of qrs) {
      const isVariable = qr.getAttribute('is-variable') === 'true';
      const variable = allVariables.find((x) => x._id === qr.getAttribute('variable'));
      const value =
        (isVariable ? keyValues[variable?.name] ?? variable?.defaultValue : qr.getAttribute('value')) || '???';

      QRCode.toDataURL(value, {}, function (err, url) {
        if (err) {
          console.log(err);
          return;
        }

        qr.src = url;
      });
    }
  });

  useEffect(() => {
    const width = pxToMm(previewRef.current?.offsetWidth);
    const height = pxToMm(previewRef.current?.offsetHeight);

    setSize({ width, height });
  }, [template, setSize]);

  useEffect(() => {
    setPreviewRef(previewRef);
  }, [setPreviewRef]);

  const handleClickElement = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const target = e.target.closest('.element');

    if (target) {
      const path = target.getAttribute('data-path');
      setEditingPath(path);
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        overflow: 'auto',
        width: '100%',
        padding: 8,
        justifyContent: 'center',
      }}
    >
      <BarcodeWrapper
        ref={previewRef}
        id="barcode-preview"
        className="barcode-editor drop-area"
        data-path="options.items"
        {...useDropProps()}
        onClick={handleClickElement}
        style={{ height: `${height}mm`, width: `${width}mm`, margin: '0 auto' }}
      >
        {useElementRenderer(items, 'options.items')}
      </BarcodeWrapper>
    </div>
  );
};

const useElementRenderer = (elements = [], path = '') => {
  const { keyValues } = useEditor();
  const allVariables = useDataSource(MODEL.VARIABLE).list;
  const dropProps = useDropProps();

  const itemRender =
    (path) =>
    ({ itemType, options = {}, barcodeOptions }, index) => {
      const itemPath = `${path}.[${index}]`;

      if (itemType === 'barcode') {
        const { isVariable, ...opts } = options;
        opts['is-variable'] = Boolean(isVariable).toString();
        return (
          <svg
            key={itemPath}
            id={`barcode-${nanoid(5)}`}
            className="display-barcode element"
            data-config={JSON.stringify(barcodeOptions)}
            {...opts}
            {...dragProps}
            data-path={itemPath}
          />
        );
      }
      if (itemType === 'qr') {
        const { isVariable, ...opts } = options;
        opts['is-variable'] = Boolean(isVariable).toString();

        return (
          <img
            alt=""
            key={itemPath}
            id={`qr-${nanoid(5)}`}
            className="display-qr element"
            {...opts}
            {...dragProps}
            data-path={itemPath}
          />
        );
      }

      if (itemType === 'text') {
        const { isVariable, variable, ...opts } = options;
        const _var = allVariables.find((x) => x._id === variable);
        const varValue = (keyValues[_var?.name] ?? _var?.defaultValue) || '???';
        return (
          <p key={itemPath} className="element" data-path={itemPath} {...opts} {...dragProps}>
            {isVariable ? varValue : options.text || 'Click para editar'}
          </p>
        );
      }
      if (itemType === 'image') {
        const { base64, ...opts } = options;
        return (
          <img key={itemPath} className="element" {...opts} {...dragProps} alt="" src={base64} data-path={itemPath} />
        );
      }
      if (itemType === 'box') {
        let style = { ...options.style };
        if (!options?.items?.length) {
          style.minWidth = 32;
          style.border = '1px dashed black';
        }
        return (
          <div
            key={itemPath}
            className="element drop-area"
            {...dropProps}
            {...options}
            style={style}
            data-path={itemPath}
            {...dragProps}
          >
            {options.items.map(itemRender(`${itemPath}.options.items`))}
          </div>
        );
      }

      return null;
    };

  return elements.map(itemRender(path));
};

const BarcodeWrapper = styled.div`
  border: 0.5px solid black;
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }

  .element {
    cursor: pointer;
  }
`;
