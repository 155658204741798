import { TabPanel, TreeList } from 'devextreme-react';
import { DraggableItem } from './Components';
import { dragProps } from './utils';
import { Item } from 'devextreme-react/tab-panel';
import { useEditor } from '.';
import { useMemo } from 'react';

export const Elements = () => {
  const { template, setEditingPath } = useEditor();

  const treeDataSource = useMemo(() => {
    const mapper = (path) => {
      return (item, index) => {
        let items = [];
        const finalPath = `${path}.[${index}]`;
        if (item.options.items) {
          items = item.options.items.map(mapper(`${finalPath}.options.items`));
        }

        return {
          text: `Item ${item.itemType} ${index}`,
          items,
          path: finalPath,
        };
      };
    };

    return template.options.items.map(mapper(`options.items`));
  }, [template]);

  const handleDragStart = (type) => (e) => {
    e.dataTransfer.setData('action', 'ADD');
    e.dataTransfer.setData('itemType', type);
  };

  return (
    <TabPanel width={300}>
      <Item title={'Elementos'}>
        <div
          style={{
            overflow: 'hidden auto',
            display: 'flex',
            flexDirection: 'column',
            gap: 8,
            padding: 8,
          }}
        >
          <DraggableItem {...dragProps} onDragStart={handleDragStart('text')}>
            <i className="dx-icon-font" />
            Texto
          </DraggableItem>
          <DraggableItem {...dragProps} onDragStart={handleDragStart('image')}>
            <i className="dx-icon-image" />
            Imágen
          </DraggableItem>
          <DraggableItem {...dragProps} onDragStart={handleDragStart('barcode')}>
            <i className="dx-icon-menu" style={{ rotate: '90deg' }} />
            Código barras
          </DraggableItem>
          <DraggableItem {...dragProps} onDragStart={handleDragStart('qr')}>
            <i className="dx-icon-mediumiconslayout" />
            Código QR
          </DraggableItem>
          <DraggableItem {...dragProps} onDragStart={handleDragStart('box')}>
            <i className="dx-icon-square" />
            Contenedor
          </DraggableItem>
        </div>
      </Item>
      <Item title={'Estructura'}>
        <TreeList
          showColumnHeaders={false}
          columns={[
            {
              name: 'Nombre',
              dataField: 'text',
            },
          ]}
          onRowClick={(item) => {
            setEditingPath(item.data.path);
          }}
          dataStructure="tree"
          dataSource={treeDataSource}
        />
      </Item>
    </TabPanel>
  );
};
